import Vue from 'vue';
import firebase from '@/firebase';
import EmployeeState from '@/types/employee';

const db = firebase.firestore();

const initialState: EmployeeState = {
  id: '',
  ref: null,
  activated: false,
  agreeTerms: false,
  beginTimes: null,
  capacity: 0,
  dropByWhenGoingHome: false,
  email: '',
  finishTimes: null,
  geoPoint: null,
  location: '',
  maxDrivingTime: 0,
  organizationRef: null,
  displayName: '',
  photoURL: '',
  phoneNumber: '',
  type: null,
  workplaceName: '',
  workplaceLocation: '',
  workplaceGeoPoint: null,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
};

const state = {
  employeeState: initialState,
  employeesState: [],
};

const getters = {
  employee: (state: any) => state.employeeState,
  employees: (state: any) => state.employeesState,
  findEmployeeById: (state: any) => (id: string) =>
    state.employeesState.find((e: EmployeeState) => (e.ref || {}).id === id),
};

const mutations = {
  init: (state: any) => (state.employeeState = initialState),
  set: (state: any, payload: EmployeeState) => (state.employeeState = payload),
  update: (state: any, payload: EmployeeState) => {
    let key: keyof EmployeeState;
    for (key in payload) {
      state.employeeState[key] = payload[key];
    }
  },
  initList: (state: any) => (state.employeesState = []),
  setList: (state: any, payload: Array<EmployeeState>) =>
    state.employeesState.push(payload),
  removeList: (state: any, payload: EmployeeState) => {
    const i: number = state.employeesState.findIndex(
      (v: EmployeeState) => v.ref === payload.ref
    );
    Vue.delete(state.employeesState, i);
  },
};

const actions = {
  async listEmployees({ commit }: any, payload: EmployeeState) {
    if (!payload.organizationRef) return;
    return await db
      .collection('employees')
      .where('organizationRef', '==', payload.organizationRef)
      .get()
      .then((doc) => {
        doc.docs.forEach((d) => {
          const data = Object.assign(
            {
              ref: d.ref,
            },
            d.data()
          );
          data.createdAt = data.createdAt.toDate();
          data.updatedAt = data.updatedAt.toDate();
          commit('setList', data);
        });
        return true;
      })
      .catch(() => {
        commit('initList');
        return false;
      });
  },
  async listAllEmployees({ commit }: any) {
    return await db
      .collection('employees')
      .get()
      .then((doc) => {
        doc.docs.forEach((d) => {
          const data = Object.assign(
            {
              ref: d.ref,
            },
            d.data()
          );
          data.createdAt = data.createdAt.toDate();
          data.updatedAt = data.updatedAt.toDate();
          commit('setList', data);
        });
        return true;
      })
      .catch(() => {
        commit('initList');
        return false;
      });
  },
  async getEmployee({ commit }: any, payload: EmployeeState) {
    if (!payload.id) return;
    return await db
      .doc('employees/' + payload.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          commit('init');
          return null;
        }
        const data = Object.assign(
          {
            ref: doc.ref,
          },
          doc.data()
        );
        data.createdAt = data.createdAt.toDate();
        data.updatedAt = data.updatedAt.toDate();
        commit('set', data);
        return data;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
  async createEmployee({ commit }: any, payload: EmployeeState) {
    payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return await db
      .collection('employees')
      .doc(payload.id)
      .set(payload)
      .then(() => {
        commit('set', payload);
        return payload;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
  async updateEmployee({ commit }: any, payload: EmployeeState) {
    if (!payload.ref) return;
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return await db
      .doc(payload.ref.path)
      .update(payload)
      .then(() => {
        commit('update', payload);
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
  async deleteEmployee({ commit }: any, payload: EmployeeState) {
    if (!payload.ref) return;
    return await db
      .doc(payload.ref.path)
      .delete()
      .then(() => {
        commit('removeList', payload);
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
