import Organization from '@/types/organization';
import MsgCache from '@/utils/msgCache';

const state = {};

const getters = {};

const mutations = {
  init: () => {
    //
  },
};

const actions = {
  async createDeviceGroup({ commit }: any, payload: Organization) {
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/msgRegister', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        orgnaizationId: payload.id,
      }),
    }).then((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          console.error(JSON.parse(text));
        });
        commit('init');
        return null;
      }
      response.text().then((text) => {
        return JSON.parse(text);
      });
    });
  },
  async addToken({ commit }: any, payload: Organization) {
    const token = MsgCache.token;
    if (!token || !payload.ref || !payload.fcmNotificationKey) {
      return;
    }
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/msgRegister', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        token: token,
        organizationId: payload.ref.id,
        fcmNotificationKey: payload.fcmNotificationKey,
      }),
    }).then((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          console.error(JSON.parse(text));
        });
        commit('init');
        return false;
      }
      response.text().then((text) => {
        MsgCache.notificationKey = JSON.parse(text).notification_key;
        return true;
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
