import firebase from '../firebase';
import util from './util';

class Analytics {
  private analytics: firebase.analytics.Analytics;

  constructor() {
    this.analytics = firebase.analytics();
  }

  private ignore(): boolean {
    if (util.local) {
      return true;
    }
    return false;
  }

  setUserId(uid: string) {
    this.analytics.setUserId(uid);
  }

  logRouterView(routerName: string) {
    if (this.ignore()) {
      return;
    }
    this.analytics.setCurrentScreen(routerName);
    this.analytics.logEvent('router_view');
    console.info(
      '%cRouterView',
      'color:white; background-color:skyblue; padding:2px 4px; border-radius:2px;',
      routerName
    );
  }

  logDialogView(dialogName: string) {
    if (this.ignore()) {
      return;
    }
    this.analytics.setCurrentScreen(dialogName);
    this.analytics.logEvent('dialog_view');
    console.info(
      '%cDialogView',
      'color:white; background-color:lightgreen; padding:2px 4px; border-radius:2px;',
      dialogName
    );
  }

  logExternalPageView(externalPageName: string) {
    if (this.ignore()) {
      return;
    }
    this.analytics.setCurrentScreen(externalPageName);
    this.analytics.logEvent('external_page_view');
    console.info(
      '%cExternalPageView',
      'color:white; background-color:bisque; padding:2px 4px; border-radius:2px;',
      externalPageName
    );
  }
}

export default new Analytics();
