import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import firebase from '../firebase';
import { routerNames } from '@/types/routerNames';
import Analytics from '@/utils/analytics';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: routerNames.login,
    component: () => import('../views/employee/Login.vue'),
  },
  {
    path: '/',
    name: routerNames.schedule,
    component: () => import('../views/employee/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/route-to-work',
    name: routerNames.routeToWork,
    component: () => import('../views/employee/RouteToWork.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/route-from-work',
    name: routerNames.routeFromWork,
    component: () => import('../views/employee/RouteFromWork.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help',
    name: routerNames.help,
    component: () => import('../views/employee/Help.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact',
    name: routerNames.contact,
    component: () => import('../views/employee/Contact.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: routerNames.admin,
    component: () => import('../views/admin/Home.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/lp',
    name: routerNames.lp,
    component: () => import('../views/lp/Home.vue'),
  },
  {
    path: '*',
    name: routerNames.notFound,
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((recode) => recode.meta.requiresAuth)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        next({
          query: {
            redirect: to.fullPath,
          },
          name: routerNames.login,
        });
      } else {
        Analytics.setUserId(user.uid);
        next();
      }
      Analytics.logRouterView(to.name || to.path);
    });
  } else if (to.matched.some((recode) => recode.meta.requiresAdmin)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (
        !user ||
        !JSON.parse(process.env.VUE_APP_ADMIN_EMAIL).includes(user.email)
      ) {
        next({
          name: routerNames.login,
        });
      } else {
        next();
      }
    });
  } else {
    Analytics.logRouterView(to.name || to.path);
    next();
  }
});

router.afterEach((to, from) => {
  if (from.path !== '/' && to.path === '/' && from.path !== to.path) {
    //
  }
});

export default router;
