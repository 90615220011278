// ユーザー
interface UserState {
  uid: string;
  email: string;
  password: string;
}

const state = {
  usersState: [],
};

const getters = {
  users: (state: any) => state.usersState,
};

const mutations = {
  init: (state: any) => (state.usersState = []),
  set: (state: any, payload: UserState) => state.usersState.push(payload),
};

const actions = {
  async listUsers({ commit }: any) {
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/users').then(
      (response) => {
        if (!response.ok) {
          commit('init');
          return false;
        }
        response.text().then((text) => {
          const users = JSON.parse(text).users;
          users.forEach((user: any) => {
            commit('set', user);
          });
        });
        return true;
      }
    );
  },
  async createUser({ commit }: any, payload: UserState) {
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          console.error(JSON.parse(text));
        });
        commit('init');
        return false;
      }
      response.text().then((text) => {
        commit('set', JSON.parse(text));
      });
      return true;
    });
  },
  async updateUser({ commit }: any, payload: UserState) {
    if (!payload.uid) return;
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/users', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          console.error(JSON.parse(text));
        });
        commit('init');
        return false;
      }
      return true;
    });
  },
  async deleteUser({ commit }: any, payload: UserState) {
    if (!payload.uid) return;
    return await fetch(process.env.VUE_APP_FUNCTION_URL + '/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          console.error(JSON.parse(text));
        });
        commit('init');
        return false;
      }
      return true;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
