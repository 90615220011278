import Vue from 'vue';
import firebase from '@/firebase';
import Organization from '@/types/organization';

const db = firebase.firestore();

const initialState: Organization = {
  id: '',
  ref: null,
  activated: false,
  geoPoint: null,
  location: '',
  name: '',
  fcmNotificationKey: '',
  sameWorkplace: true,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
};

const state = {
  organizationState: initialState,
  organizationsState: [],
};

const getters = {
  organization: (state: any) => state.organizationState,
  organizations: (state: any) => state.organizationsState,
  findOrganizationById: (state: any) => (id: string) =>
    state.organizationsState.find((o: Organization) => (o.ref || {}).id === id),
};

const mutations = {
  init: (state: any) => (state.organizationState = initialState),
  set: (state: any, payload: Organization) =>
    (state.organizationState = payload),
  update: (state: any, payload: Organization) => {
    let key: keyof Organization;
    for (key in payload) {
      state.organizationState[key] = payload[key];
    }
  },
  initList: (state: any) => (state.organizationsState = []),
  setList: (state: any, payload: Array<Organization>) =>
    state.organizationsState.push(payload),
  removeList: (state: any, payload: Organization) => {
    const i: number = state.organizationsState.findIndex(
      (v: Organization) => v.ref === payload.ref
    );
    Vue.delete(state.organizationsState, i);
  },
};

const actions = {
  async listOrganizations({ commit }: any) {
    return await db
      .collection('organizations')
      .get()
      .then((doc) => {
        doc.docs.forEach((d1) => {
          const d2: any = d1.data();
          d2.id = d1.id;
          d2.ref = d1.ref;
          d2.createdAt = d2.createdAt.toDate();
          d2.updatedAt = d2.updatedAt.toDate();
          commit('setList', d2);
        });
        return true;
      })
      .catch(() => {
        commit('initList');
        return false;
      });
  },
  async getOrganization({ commit }: any, payload: Organization) {
    if (!payload.id) return;
    return await db
      .doc('organizations/' + payload.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          commit('init');
          return null;
        }
        const data = Object.assign(
          {
            ref: doc.ref,
          },
          doc.data()
        );
        data.createdAt = data.createdAt.toDate();
        data.updatedAt = data.updatedAt.toDate();
        commit('set', data);
        return data;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
  async createOrganization({ commit }: any, payload: Organization) {
    payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return await db
      .collection('organizations')
      .add(payload)
      .then((doc) => {
        payload.ref = doc;
        commit('set', payload);
        return payload;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
  async updateOrganization({ commit }: any, payload: Organization) {
    if (!payload.ref) return;
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return await db
      .doc(payload.ref.path)
      .update(payload)
      .then(() => {
        commit('update', payload);
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
