interface UiAppState {
  showApp: boolean;
  showSplash: boolean;
  showTerms: boolean;
}

const initialState: UiAppState = {
  showApp: false,
  showSplash: false,
  showTerms: false,
};

const state = {
  uiAppState: initialState,
};

const getters = {
  uiApp: (state: any) => state.uiAppState,
};

const mutations = {
  init: (state: any) => (state.uiAppState = initialState),
  setShowApp: (state: any, showApp: boolean) =>
    (state.uiAppState.showApp = showApp),
  setShowSplash: (state: any, showSplash: boolean) =>
    (state.uiAppState.showSplash = showSplash),
  setShowTerms: (state: any, showTerms: boolean) =>
    (state.uiAppState.showTerms = showTerms),
};

const actions = {
  initUiAppShowSplash({ commit }: any) {
    if (sessionStorage.getItem('uiAppShowSplash')) {
      commit('setShowApp', true);
      return;
    }
    commit('setShowSplash', true);
    setTimeout(() => {
      commit('setShowSplash', false);
      sessionStorage.setItem('uiAppShowSplash', 'true');
    }, 2500);
    setTimeout(() => {
      commit('setShowApp', true);
    }, 3000);
  },
  setUiAppShowTerms({ commit }: any) {
    commit('setShowTerms', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
