import firebase from '@/firebase';
import CarpoolLogState from '@/types/carpoolLog';
import { ResultType } from '@/types/employeeCarpoolLog';
import { Commute } from '@/types/commute';

const db = firebase.firestore();

const state = {
  todayCarpoolToWorkLog: null,
  todayCarpoolFromWorkLog: null,
};

const getters = {
  todayCarpoolToWorkLog: (state: any) => state.todayCarpoolToWorkLog,
  todayCarpoolFromWorkLog: (state: any) => state.todayCarpoolFromWorkLog,
};

const mutations = {
  init: (state: any) => {
    state.todayCarpoolToWorkLog = null;
    state.todayCarpoolFromWorkLog = null;
  },
  set: (state: any, payload: CarpoolLogState) => {
    if (payload.commute === Commute.toWork) {
      payload.results = payload.results || {};
      state.todayCarpoolToWorkLog = payload;
    } else if (payload.commute === Commute.fromWork) {
      payload.results = payload.results || {};
      state.todayCarpoolFromWorkLog = payload;
    }
  },
};

const actions = {
  async getCarpoolLog({ commit }: any, payload: CarpoolLogState) {
    if (!payload.organizationId || !payload.date) return;
    return await db
      .collection('carpoolLogs')
      .where('organizationId', '==', payload.organizationId)
      .where('date', '==', payload.date)
      .get()
      .then((doc) => {
        commit('init');
        doc.docs.forEach((d) => {
          commit(
            'set',
            Object.assign(
              {
                ref: d.ref,
              },
              d.data()
            )
          );
        });
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
  async updateCarpoolLog(
    { commit }: any,
    payload: {
      log: CarpoolLogState;
      employeeId: string;
      result: ResultType;
    }
  ) {
    if (!payload.log || !payload.log.ref) return;
    const results = payload.log.results || {};
    results[payload.employeeId] = payload.result;
    const doc = {
      results: results,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return await db
      .collection('carpoolLogs')
      .doc(payload.log.ref.id)
      .update(doc)
      .then(() => {
        commit('set', Object.assign(payload.log, doc));
        return true;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
