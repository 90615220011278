import firebase from '@/firebase';

const db = firebase.firestore();

// 退勤時の最適ルート
interface CpFromWorkOutputState {
  id: string;
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
}

const initialState: CpFromWorkOutputState = {
  id: '',
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
};

const state = {
  cpFromWorkOutputState: initialState,
};

const getters = {
  findFeasiblePathFromWork: (state: any) => (weekday: string) =>
    state.cpFromWorkOutputState[weekday],
  findFeasiblePathFromWorkByServer: (state: any) => (
    weekday: string,
    serverId: string
  ) =>
    state.cpFromWorkOutputState[weekday].feasiblePaths.find(
      (f: any) => f.server === serverId
    ),
  findFeasiblePathFromWorkByClient: (state: any) => (
    weekday: string,
    clientId: string
  ) =>
    state.cpFromWorkOutputState[weekday].feasiblePaths.find((f: any) =>
      f.clients.includes(clientId)
    ),
};

const mutations = {
  init: (state: any) => (state.cpFromWorkOutputState = initialState),
  set: (state: any, payload: CpFromWorkOutputState) =>
    (state.cpFromWorkOutputState = payload),
};

const actions = {
  async getCpFromWorkOutput({ commit }: any, payload: CpFromWorkOutputState) {
    if (!payload.id) return;
    return await db
      .doc('organizations/' + payload.id + '/cpFromWorkOutputs/' + payload.id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          commit('init');
          return null;
        }
        const data = Object.assign(
          {
            ref: doc.ref,
          },
          doc.data()
        );
        commit('set', data);
        return data;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
