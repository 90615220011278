











import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Splash from '@/components/employee/Splash.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Splash,
  },
  computed: {
    ...mapGetters('UiAppModule', ['uiApp']),
  },
  methods: {
    ...mapActions('UiAppModule', ['initUiAppShowSplash']),
  },
  mounted() {
    this.initUiAppShowSplash();
  },
});
