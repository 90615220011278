import Vue from 'vue';
import Vuex from 'vuex';
import EmployeesCarpoolLogModule from './employees/carpoolLog';
import EmployeesCpFromWorkOutputModule from '../store/employees/cpFromWorkOutput';
import EmployeesCpToWorkOutputModule from '../store/employees/cpToWorkOutput';
import EmployeesEmployeeModule from '../store/employees/employee';
import EmployeesEmployeeCarpoolLogModule from './employees/employeeCarpoolLog';
import EmployeesMessagingModule from './employees/messaging';
import EmployeesOrganizationModule from '../store/employees/organization';
import EmployeesUserModule from '../store/employees/user';
import StorageProfilePhotoModule from '../store/storage/profilePhoto';
import UiAppModule from '../store/ui/app';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    EmployeesCarpoolLogModule: EmployeesCarpoolLogModule,
    EmployeesCpFromWorkOutputModule: EmployeesCpFromWorkOutputModule,
    EmployeesCpToWorkOutputModule: EmployeesCpToWorkOutputModule,
    EmployeesEmployeeModule: EmployeesEmployeeModule,
    EmployeesEmployeeCarpoolLogModule: EmployeesEmployeeCarpoolLogModule,
    EmployeesMessagingModule: EmployeesMessagingModule,
    EmployeesOrganizationModule: EmployeesOrganizationModule,
    EmployeesUserModule: EmployeesUserModule,
    StorageProfilePhotoModule: StorageProfilePhotoModule,
    UiAppModule: UiAppModule,
  },
});
