class MsgCache {
  private key = {
    token: 'app:fcm-current-token',
    notificationKey: 'app:fcm-notification-key',
  };

  get token(): string | null {
    return localStorage.getItem(this.key.token);
  }

  set token(token: string | null) {
    if (!token) {
      return;
    }
    localStorage.setItem(this.key.token, token);
  }

  get notificationKey(): string | null {
    return localStorage.getItem(this.key.notificationKey);
  }

  set notificationKey(notificationKey: string | null) {
    if (!notificationKey) {
      return;
    }
    localStorage.setItem(this.key.notificationKey, notificationKey);
  }
}

export default new MsgCache();
