import firebase from '@/firebase';
import EmployeeCarpoolLogState from '@/types/employeeCarpoolLog';
import { Commute } from '@/types/commute';

const db = firebase.firestore();

const state = {
  todayEmployeeCarpoolToWorkLog: null,
  todayEmployeeCarpoolFromWorkLog: null,
};

const getters = {
  todayEmployeeCarpoolToWorkLog: (state: any) =>
    state.todayEmployeeCarpoolToWorkLog,
  todayEmployeeCarpoolFromWorkLog: (state: any) =>
    state.todayEmployeeCarpoolFromWorkLog,
};

const mutations = {
  init: (state: any) => {
    state.todayEmployeeCarpoolToWorkLog = null;
    state.todayEmployeeCarpoolFromWorkLog = null;
  },
  set: (state: any, payload: EmployeeCarpoolLogState) => {
    if (payload.commute === Commute.toWork) {
      state.todayEmployeeCarpoolToWorkLog = payload;
    } else if (payload.commute === Commute.fromWork) {
      state.todayCarpoolFromWorkLog = payload;
    }
  },
};

const actions = {
  async listTodayEmployeeCarpoolLogs(
    { commit }: any,
    payload: EmployeeCarpoolLogState
  ) {
    if (!payload.employeeRef) return;
    return await db
      .collection('employees')
      .doc(payload.employeeRef.id)
      .collection('carpoolLogs')
      .where('date', '==', payload.date)
      .get()
      .then((doc) => {
        doc.docs.forEach((d) => {
          const data = Object.assign(
            {
              ref: d.ref,
            },
            d.data()
          );
          data.createdAt = data.createdAt.toDate();
          commit('set', data);
        });
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
  async createEmployeeCarpoolLog(
    { commit }: any,
    payload: EmployeeCarpoolLogState
  ) {
    if (!payload.employeeRef) return;
    payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    return await db
      .collection('employees')
      .doc(payload.employeeRef.id)
      .collection('carpoolLogs')
      .add(payload)
      .then(() => {
        commit('set', payload);
        return payload;
      })
      .catch(() => {
        return null;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
