import { ja } from 'vuetify/src/locale';

export default {
  ...ja,

  app: {
    title: "Let's Carpool",
    page: {
      help: 'ヘルプ',
      contact: 'お問い合わせ',
    },
  },
  help: {
    0: {
      subtitle: '事故',
      items: {
        0: {
          q: '通勤中に交通事故に遭った',
          a: '勤務先・警察にご連絡の上、指示に従ってください。',
        },
        1: {
          q: '通勤中に交通事故を起こした',
          a: '勤務先・警察にご連絡の上、指示に従ってください。',
        },
      },
    },
    1: {
      subtitle: '遅刻、欠勤、早退 / 残業',
      items: {
        0: {
          q: '出勤時間に遅刻した',
          a:
            '運転者・同乗者両人とも遅刻になる可能性がありますが、判断は勤務規定に準拠します。',
        },
        1: {
          q: '運転者が待ち合わせ時間に来ない',
          a:
            'まずは電話 / メールでご連絡ください。連絡つかない場合、普段の交通手段でご通勤ください。大変ご迷惑をおかけします。',
        },
        2: {
          q: '同乗者が待ち合わせ時間に来ない',
          a:
            'まずは電話 / メールでご連絡ください。待ち合わせ時間になっても連絡つかない場合、置いてご出発ください。',
        },
        3: {
          q: '運転者の欠勤',
          a:
            '運転者の方は、急遽、会社を休む場合、当日の同乗者全員にご連絡ください。同乗者の方は、普段の交通手段でご出勤ください。大変ご迷惑をおかけします。',
        },
        4: {
          q: '同乗者の欠勤',
          a:
            '同乗者の方は、急遽、会社を休む場合、当日の運転者にご連絡ください。運転者の方は、ルートには欠勤する同乗者の方が表示されますが、スキップしてご出発ください。',
        },
        5: {
          q: '運転者の早退 / 残業',
          a:
            '急遽、早退・残業で運転できない場合、当日の同乗者全員にご連絡ください。同乗者の方は、普段の交通手段でご退勤ください。大変ご迷惑をおかけします。',
        },
        6: {
          q: '同乗者の早退 / 残業',
          a:
            '急遽、早退・残業で相乗りしない場合、当日の運転者にご連絡ください。',
        },
      },
    },
    2: {
      subtitle: 'カープール',
      items: {
        0: {
          q: 'マッチングしなかった',
          a: '普段の交通手段でご通勤ください。大変ご迷惑をおかけします。',
        },
        1: {
          q: '会社到着時間がギリギリ',
          a:
            '到着予想時間は Google Map の到着時間に数分の乗降時間を加えた時間です。深刻な渋滞の場合、予想を超える可能性がありますので、通勤設定の出勤時間は余裕のある時間をご指定ください。',
        },
        2: {
          q: '運転者と同乗者の役割りを変えたい',
          a: '運営にお問い合わせください。',
        },
        3: {
          q: '運転者のインセンティブ',
          a: '運営にお問い合わせください。',
        },
      },
    },
  },
};
