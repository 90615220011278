export const routerNames = {
  login: 'login',
  schedule: 'schedule',
  routeToWork: 'route_to_work',
  routeFromWork: 'route_from_work',
  help: 'help',
  contact: 'contact',
  splash: 'splash',
  admin: 'admin',
  lp: 'landing_page',
  notFound: 'not_found',
} as const;
