















import Vue from 'vue';
import Analytics from '@/utils/analytics';
import { routerNames } from '@/types/routerNames';

export default Vue.extend({
  data: () => ({
    year: new Date().getFullYear(),
  }),
  created() {
    Analytics.logRouterView(routerNames.splash);
  },
});
